/* eslint-disable indent */
export const PENDING_PATH_KEY = 'PENDING_PATH';
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const MASTER = 'master';

export const REDIRECT_URL = process.env.REACT_APP_WEBSITE_URL;

export const API_URL = process.env.REACT_APP_API_URL || '';

export const ENDPOINT = API_URL.replace('api', '');

export const formatToCurrency = (amount) =>
  amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const TIMELINE_URL = process.env.REACT_APP_TIMELINE_URL;

export const FOUNDER_ERP_URL = process.env.REACT_APP_FOUNDER_URL;
