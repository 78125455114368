import React, { useContext, useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useCookies } from 'react-cookie';
import { ThemeContext } from './ThemeWrapper';
import { fetchProfile } from '../../redux/actions/profileActions';

import {
  Members,
  PersonalDashboard,
  Profile,
  Settings,
  NotFound,
  Discover,
  Screening,
  Startup,
  InitialPitch,
  Offline,
  Chat,
  Email,
  Syndicate,
  Syndication,
  Transaction,
} from '../pageListAsync';

import Dashboard from '../Templates/Dashboard';
import { registerServiceWorker } from '../../worker';
import NotificationPermissionModal from './NotificationPermissionModal';
import {
  extractNetwork,
  getWithExpiry,
  setWithExpiry,
} from '../../utils/helpers';
import http from '../../redux/api';
import { networkRequests } from '../../redux/api/requests';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';
import { PENDING_PATH_KEY } from '../../utils/constants';

function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}

function isiOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

function Application(props) {
  const { history } = props;
  const isDesktop = window.screen.width >= 992;
  const profile = useSelector((state) => state.toJS().profile.data);
  const dispatch = useDispatch();
  const isLoaded = useRef(false);
  const [openUpdateSnack, setOpenUpdateSnack] = useState(false);
  const [openPermissionsModal, setOpenPermissionsModal] = useState(false);
  const [
    openFailedNotificationSnack,
    setOpenFailedNotificationSnack,
  ] = useState(false);
  const [updateSnackMessage, setUpdateSnackMessage] = useState(
    'New version of app is available'
  );
  const [
    failedNotificationSnackMessage,
    setFailedNotificationSnackMessage,
  ] = useState('');

  const registerUserForNotifications = () =>
    navigator.serviceWorker &&
    navigator.serviceWorker.ready.then((registration) =>
      registerServiceWorker(registration)
    );

  const handleNotificationPermission = (permission) => {
    if (permission === 'granted') {
      return registerUserForNotifications();
    }
    setFailedNotificationSnackMessage(`Your device prevented us from getting the required permission.
Please allow notifications from your device settings. ${
      isDesktop
        ? 'Open the guide slide by clicking the fourth icon from the left on the top left part of the screen to see how to do this on your browser.'
        : ''
    }`);
    return setOpenFailedNotificationSnack(true);
  };

  const requestPermission = async () => {
    // check if promise based version of notifications api is supported. Screw you Safari
    if (checkNotificationPromise()) {
      const permission = await Notification.requestPermission();
      return handleNotificationPermission(permission);
    }

    // ah, guy is on the godawful browser called Safari.
    Notification.requestPermission((permission) =>
      handleNotificationPermission(permission)
    );
  };

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if ('Notification' in window && 'PushManager' in window) {
        // user has given us permission to show notifications
        if (Notification.permission === 'granted') {
          registerUserForNotifications();
        } else {
          // they haven't, request for it after 10 seconds so we do not frustrate the user
          setTimeout(() => {
            setOpenPermissionsModal(true);
          }, 10000);
        }
      } else {
        const shouldShowWarningKey = getWithExpiry(
          'conectivest-show-notification-warning'
        );
        if (!isiOS() && !shouldShowWarningKey) {
          const nextShowTime = 2629800000; // one month
          setOpenFailedNotificationSnack(true);
          setFailedNotificationSnackMessage(
            `Your browser does not support notifications. This means we cannot send you notifications for activities happening on your network.
Please try updating your browser or use a different browser.`
          );
          setWithExpiry(
            'conectivest-show-notification-warning',
            'true',
            nextShowTime
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    const handleControllerChange = () => {
      setOpenUpdateSnack(true);
    };
    navigator.serviceWorker.addEventListener(
      'controllerchange',
      handleControllerChange
    );
    return () =>
      navigator.serviceWorker.removeEventListener(
        'controllerchange',
        handleControllerChange
      );
  }, []);

  // this is a backward compatibility patch. Do not delete it
  // the discover and screening tab working on first load for a new user depends on it
  // you can improve it by detecting if the user is new. But DO NOT DELETE IT!
  useEffect(() => {
    if (!isLoaded.current && profile.data) {
      const { networkId } = extractNetwork(profile);
      if (networkId) {
        http.get(networkRequests.FETCH_DEALFLOW(networkId));
        isLoaded.current = true;
      }
    }
  }, [profile]);

  const changeMode = useContext(ThemeContext);

  const handleClose = () => setOpenUpdateSnack(false);

  const updateApp = async () => {
    setUpdateSnackMessage('Updating app. Page will reload shortly');
    window.location.reload();
  };

  return (
    <Dashboard history={history} changeMode={changeMode}>
      <NotificationPermissionModal
        open={openPermissionsModal}
        close={() => setOpenPermissionsModal(false)}
        askForPermission={requestPermission}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openFailedNotificationSnack}
        autoHideDuration={null}
        onClose={() => setOpenFailedNotificationSnack(false)}
        message={failedNotificationSnackMessage}
        action={
          <React.Fragment>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => setOpenFailedNotificationSnack(false)}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openUpdateSnack}
        autoHideDuration={null}
        onClose={handleClose}
        message={updateSnackMessage}
        action={
          <React.Fragment>
            <Button color='primary' size='small' onClick={updateApp}>
              Update
            </Button>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      />
      <Switch>
        {/* Home */}
        <Route exact path='/app' component={PersonalDashboard} />
        {/* <Route path='/app/crm-dashboard' component={CrmDashboard} />
        <Route path='/app/crypto-dashboard' component={CryptoDashboard} /> */}
        {/* Widgets */}
        {/* <Route path='/app/widgets/infographics' component={Infographics} />
        <Route path='/app/widgets/mini-apps' component={MiniApps} />
        <Route path='/app/widgets/analytics' component={Analytics} />
        <Route path='/app/widgets/info-updates' component={InfoUpdates} />
        <Route path='/app/widgets/status' component={Status} /> */}
        {/* Layout */}
        {/* <Route exact path='/app/layouts' component={Parent} />
        <Route path='/app/layouts/grid' component={Grid} />
        <Route path='/app/layouts/app-layout' component={AppLayout} />
        <Route path='/app/layouts/responsive' component={Responsive} /> */}
        {/* Table */}
        {/* <Route exact path='/app/tables' component={Parent} />
        <Route path='/app/tables/basic-table' component={SimpleTable} />
        <Route path='/app/tables/data-table' component={AdvancedTable} />
        <Route
          path='/app/tables/table-playground'
          component={TablePlayground}
        />
        <Route path='/app/tables/tree-table' component={TreeTable} />
        <Route path='/app/tables/editable-cell' component={EditableCell} /> */}
        {/* Form & Button */}
        {/* <Route exact path='/app/forms' component={Parent} />
        <Route path='/app/forms/reduxform' component={ReduxForm} />
        <Route path='/app/forms/date-time-picker' component={DateTimePicker} />
        <Route path='/app/forms/checkbox-radio' component={CheckboxRadio} />
        <Route path='/app/forms/switches' component={Switches} />
        <Route path='/app/forms/selectbox' component={Selectbox} />
        <Route path='/app/forms/ratting' component={Rating} />
        <Route path='/app/forms/slider-range' component={SliderRange} />
        <Route path='/app/forms/buttons' component={Buttons} />
        <Route path='/app/forms/toggle-button' component={ToggleButton} />
        <Route path='/app/forms/dial-button' component={DialButton} />
        <Route path='/app/forms/textfields' component={Textbox} />
        <Route path='/app/forms/autocomplete' component={Autocomplete} />
        <Route path='/app/forms/upload' component={Upload} />
        <Route path='/app/forms/wysiwyg-editor' component={TextEditor} /> */}
        {/* Ui Components */}
        {/* <Route exact path='/app/ui' component={Parent} />
        <Route path='/app/ui/avatars' component={Avatars} />
        <Route path='/app/ui/accordion' component={Accordion} />
        <Route path='/app/ui/badges' component={Badges} />
        <Route path='/app/ui/list' component={List} />
        <Route path='/app/ui/popover-tooltip' component={PopoverTooltip} />
        <Route path='/app/ui/snackbar' component={Snackbar} />
        <Route path='/app/ui/typography' component={Typography} />
        <Route path='/app/ui/tabs' component={Tabs} />
        <Route path='/app/ui/card-papper' component={Cards} />
        <Route path='/app/ui/image-grid' component={ImageGrid} />
        <Route path='/app/ui/progress' component={Progress} />
        <Route path='/app/ui/dialog-modal' component={DialogModal} />
        <Route path='/app/ui/steppers' component={Steppers} />
        <Route path='/app/ui/paginations' component={Paginations} />
        <Route path='/app/ui/drawer-menu' component={DrawerMenu} />
        <Route path='/app/ui/breadcrumbs' component={Breadcrumbs} />
        <Route path='/app/ui/icons' component={Icons} />
        <Route path='/app/ui/ionicons' component={IonIcons} />
        <Route path='/app/ui/slider-carousel' component={SliderCarousel} />
        <Route path='/app/ui/tags' component={Tags} />
        <Route path='/app/ui/dividers' component={Dividers} />
        {/* Chart */}
        {/* <Route exact path='/app/charts' component={Parent} />
        <Route path='/app/charts/line-charts' component={LineCharts} />
        <Route path='/app/charts/bar-charts' component={BarCharts} />
        <Route path='/app/charts/area-charts' component={AreaCharts} />
        <Route path='/app/charts/pie-charts' component={PieCharts} />
        <Route path='/app/charts/radar-charts' component={RadarCharts} />
        <Route path='/app/charts/scatter-charts' component={ScatterCharts} />
        <Route path='/app/charts/compossed-chart' component={CompossedCharts} />
        <Route
          path='/app/charts/doughnut-pie-charts'
          component={DoughnutCharts}
        />
        <Route
          path='/app/charts/bar-direction-charts'
          component={BarDirection}
        />
        <Route
          path='/app/charts/line-scatter-charts'
          component={LineScatterChart}
        />
        <Route
          path='/app/charts/area-filled-charts'
          component={AreaFilledChart}
        />
        <Route
          path='/app/charts/radar-polar-chart'
          component={RadarPolarCharts}
        /> */}
        {/* Sample Apps */}
        {/* <Route path='/app/pages/contact' component={Contact} />
        <Route path='/app/pages/chat' component={Chat} />
        <Route path='/app/pages/email' component={Email} />
        <Route path='/app/pages/timeline' component={Timeline} />
        <Route path='/app/pages/ecommerce' component={Ecommerce} />
        <Route path='/app/pages/product-detail' component={ProductPage} />
        <Route path='/app/pages/checkout' component={CheckoutPage} />
        <Route path='/app/pages/calendar' component={Calendar} />
        <Route path='/app/pages/taskboard' component={TaskBoard} />
        <Route path='/app/pages/invoice' component={Invoice} /> */}
        {/* Pages */}
        {/* <Route exact path='/app/pages' component={Parent} /> */}
        <Route path='/app/pages/chat' component={Chat} />
        <Route path='/app/pages/email' component={Email} />
        <Route path='/app/pages/user-profile' component={Profile} />
        {/* <Route path='/app/pages/blank-page' component={BlankPage} /> */}
        <Route path='/app/pages/dealflow/discover' component={Discover} />
        <Route path='/app/pages/dealflow/screening' component={Screening} />
        <Route
          path='/app/pages/dealflow/initialpitch'
          component={InitialPitch}
        />
        <Route
          path='/app/pages/dealflow/startup/:dealflowId/:dealId/:companyId'
          component={Startup}
        />

        {/* <Route path='/app/pages/photo-gallery' component={Photos} />
        <Route path='/app/pages/pricing' component={Pricing} /> */}
        <Route path='/app/pages/not-found' component={NotFound} />
        <Route path='/app/pages/offline' component={Offline} />
        {/* <Route path='/app/pages/error' component={Error} /> */}
        <Route path='/app/pages/settings' component={Settings} />
        {/* <Route path='/app/pages/help-support' component={HelpSupport} /> */}
        {/* Map */}
        {/* <Route exact path='/app/maps' component={Parent} />
        <Route path='/app/maps/map-marker' component={MapMarker} />
        <Route path='/app/maps/map-direction' component={MapDirection} />
        <Route path='/app/maps/map-searchbox' component={SearchMap} />
        <Route path='/app/maps/map-traffic' component={TrafficIndicator} />
        <Route path='/app/maps/street-view' component={StreetViewMap} />
        <Route path='/app/sample-page' component={SamplePage} /> */}
        {/* Map */}
        <Route exact path='/app/members' component={Members} />
        <Route exact path='/app/syndication' component={Syndication} />
        <Route
          exact
          path='/app/syndication/:id/:companyName'
          component={Syndicate}
        />
        <Route exact path='/app/transactions' component={Transaction} />

        {/* Default */}
        <Route component={NotFound} />
      </Switch>
    </Dashboard>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Application;
