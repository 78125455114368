import { call, takeLatest, put } from 'redux-saga/effects';
import cookies from '../../config/cookies';
import safeSaga from '../../utils/safeSaga';
import { PENDING_PATH_KEY } from '../../utils/constants';

import http from '../api';
import { authRequests } from '../api/requests';
import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  LOGOUT_REQUEST,
} from '../types';
import { LOGGED_IN_STATE_KEY } from '../constants';
import { clearCookies } from '../../utils/helpers';

function* signIn({ payload: { rememberMe, ...payload } }) {
  try {
    const { data } = yield call(
      [http, 'post'],
      authRequests.NEW_LOGIN,
      payload
    );
    yield put({
      type: SIGN_IN_SUCCESS,
      payload: data,
    });
    // const pendingPath = sessionStorage.getItem(PENDING_PATH_KEY) || '/app';
    // window.location.pathname = pendingPath;
  } catch (error) {
    const errorPayload = error.response && error.response.data.error;
    yield put({
      type: SIGN_IN_ERROR,
      payload: errorPayload || 'Something went wrong. Try again',
    });
  }
}

function* logout() {
  yield clearCookies();
  // yield (window.location.href = '/login');
}

export default function* authSaga() {
  yield takeLatest(SIGN_IN_REQUEST, safeSaga(signIn));
  yield takeLatest(LOGOUT_REQUEST, safeSaga(logout));
}
