module.exports = [
  {
    key: 'dashboard',
    // eslint-disable-next-line linebreak-style
    name: 'Dashboard',
    icon: 'ios-home-outline',
    linkParent: '/app',
  },
  // {
  //   key: 'members',
  //   name: 'Members',
  //   icon: 'ios-people',
  //   linkParent: '/app/members',
  // },
  {
    key: 'app',
    name: 'Apps',
    icon: 'ios-appstore-outline',
    child: [
      {
        key: 'chat',
        name: 'Chat',
        link: '/app/pages/chat',
        icon: 'ios-mail-outline',
      },
    ],
  },
  {
    key: 'syndication',
    name: 'Syndication',
    icon: 'ios-people',
    linkParent: '/app/syndication',
    isBeta: true,
  },
  {
    key: 'transactions',
    name: 'Transactions',
    icon: 'ios-stats-outline',
    linkParent: '/app/transactions',
  },
];
