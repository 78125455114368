/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import PropTypes from 'prop-types';

export function NotifyUserOfEvent({ close, message, open }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={close}
      message={message}
      action={
        <React.Fragment>
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={close}
          >
            <Close fontSize='small' />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}

NotifyUserOfEvent.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};
