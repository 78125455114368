import { baseURL } from '.';

export const userRequests = {
  PROFILE: '/profile',
  FORM_DATA: '/form-data',
  GET_NETWORK_DETAIL: (id) => `/network/${id}`,
  UPLOAD_IMAGE: '/profile/avatar',
  UPLOAD: '/founder/upload/image',
};

export const authRequests = {
  LOGIN: '/user/network/login',
  NEW_LOGIN: '/user/login',
};

export const emailRequests = {
  EMAIL: '/email',
  FETCH_SINGLE_EMAIL: (emailId, type) => `/email/${emailId}?type=${type}`,
};

export const chatRequest = {
  GET_GROUP_MESSAGE: (groupId) => `/network/group/chat/${groupId}`,
  SEND_GROUP_MESSAGE: (networkId) =>
    `/network/group/chat?networkId=${networkId}`,
  PRIVATE_MESSAGES: `${baseURL}/chat`,
  SEND_BULK_MESSAGES: `${baseURL}/chat/bulk`,
};

export const networkRequests = {
  NETWORKS: '/network',
  GET_NETWORKS_OPEN_TO_INTER_NETWORK_ACTIVITIES:
    '/network?allowInterNetworksActivites=true',
  UPLOAD: '/upload',
  SUSPEND_USER: '/network/members/suspend',
  ACTIVATE_USER: '/network/members/activate',
  UPDATE_NETWORK: '/network/update',
  FILE_TO_DEAL: '/network/deal/file',
  GET_TRANSACTIONS: (networkId) =>
    `/network/deal/transactions?networkId=${networkId}`,
  GET_MY_INTEREST: (networkId) =>
    `/network/deal/deal-in-dealflow/interest/me?networkId=${networkId}&interest=true`,
  GET_DISINTERESTS: (networkId) =>
    `/network/deal/deal-in-dealflow/interest/me?networkId=${networkId}&interest=false`,
  GET_TERMS: (dealId, networkId) =>
    `/network/deal/terms/${dealId}?networkId=${networkId}`,
  FETCH_MEMBERS: (networkId) => `/network/members?networkId=${networkId}`,
  UPDATE_MEMBER_ROLE: '/network/members/role',
  FETCH_MEMBER: (userId, networkId) =>
    `/network/members/${userId}?networkId=${networkId}`,
  FETCH_NETWORK_APPLICATIONS: (networkId) =>
    `network/applications?networkId=${networkId}&isInvited=false`,
  INVITE: '/network/applications/invite?networkId=',
  FETCH_NETWORK_MEMBER_APPLICATION: (appId, networkId) =>
    `/network/applications/${appId}?networkId=${networkId}`,
  UPDATE_APPLICATION: (appId, networkId, value) =>
    `/network/applications/${value}/${appId}?networkId=${networkId}`,
  DELETE_APPLICATION: (appId, networkId) =>
    `/network/applications/${appId}?networkId=${networkId}`,
  CREATE_GROUP: '/network/group',
  CREATE_DEAL: '/network/deal',
  EDIT_DEAL: (dealId) => `/network/deal/${dealId}`,
  ADD_MEMBER: '/network/group/members',
  FETCH_COMPANY_INFO: (networkId, compId) =>
    `/network/deal/company-info/overview/${compId}?networkId=${networkId}`,
  REMOVE_MEMBER: (groupId) => `/network/group/${groupId}/members`,
  GET_MEMBERS: (groupId) => `/network/group/${groupId}/members`,
  GET_GROUPS: `${baseURL}/network/group`,
  DELETE_GROUPS: (groupId, networkId) =>
    `/network/group/${groupId}?networkId=${networkId}`,
  UPDATE_GROUPS: (groupId) => `/network/group/${groupId}`,
  FETCH_INVITES: (networkId) =>
    `network/applications?networkId=${networkId}&isInvited=true`,
  FETCH_DEALFLOW: (networkId) =>
    `/network/deal/dealflow?networkId=${networkId}`,
  FETCH_DEALS: (networkId, dealflowId) =>
    `/network/deal/deals-in-dealflow/${dealflowId}?networkId=${networkId}`,
  FETCH_DEAL: (networkId, dealflowId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/${dealflowId}?networkId=${networkId}&dealId=${dealId}`,
  FETCH_COMMENTS_IN_DEAL: (networkId, dealflowId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/comment/${dealflowId}?networkId=${networkId}&dealId=${dealId}`,
  ADD_COMMENT_TO_DEAL: () => `${baseURL}/network/deal/deal-in-dealflow/comment`,
  FETCH_RECENT_ACTIVITIES: (networkId) =>
    `network/recent-activities?networkId=${networkId}`,
  GET_ALL_INTEREST_IN_DEAL: (networkId, dealflowId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/interest/${dealflowId}?networkId=${networkId}&dealId=${dealId}`,
  CLOSE_DEAL: (networkId, dealId) =>
    `/network/deal/close/${dealId}?networkId=${networkId}`,
  CREATE_DEAL_TERMS: (networkId, dealId) =>
    `${baseURL}/network/deal/terms/${dealId}?networkId=${networkId}`,
  GET_DEAL_TERMS: (networkId, dealId) =>
    `${baseURL}/network/deal/terms/${dealId}?networkId=${networkId}`,
  GET_NETWORK_DEAL_TERMS: (networkId) =>
    `${baseURL}/network/terms/${networkId}`,
  GET_PERMITTED_USERS_FOR_DEAL: (dealId, networkId) =>
    `${baseURL}/network/deal/deal-permissions/${dealId}?networkId=${networkId}`,
  GET_CHANNELS: `${baseURL}/chat/channel`,
  GET_PRIVATE_MESSAGES: (channelId) => `${baseURL}/chat/${channelId}`,
  REOPEN_DEAL: (networkId, dealId) =>
    `${baseURL}/network/deal/open/${dealId}?networkId=${networkId}`,
  ARCHIVE_DEAL: (networkId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/${dealId}/archive?networkId=${networkId}`,
  RESEND_INVITE: (networkId) =>
    `${baseURL}/network/bulk-signup/resend-invite?networkId=${networkId}`,
  CREATE_SYNDICATE: `${baseURL}/syndicate`,
  EDIT_SYNDICATE: (syndicateId) => `${baseURL}/syndicate/${syndicateId}`,
  GET_SYNDICATES: `${baseURL}/syndicate`,
  GET_SYNDICATE: (syndicateId) => `${baseURL}/syndicate/${syndicateId}`,
  GET_SYNDICATE_MEMBERS: (syndicateId) =>
    `${baseURL}/syndicate/members/${syndicateId}`,
  GET_SYNDICATE_REQUESTS: `${baseURL}/syndicate/request`,
  TOGGLE_SYNDICATE_REQUEST_STATUS: `${baseURL}/syndicate/request`,
  GET_ALL_DEALS: (networkId) =>
    `${baseURL}/network/deal?networkId=${networkId}`,
  SEND_CROSS_SYNDICATE_INVITES: (networkId) =>
    `${baseURL}/syndicate/networks/${networkId}/request`,
  ACCEPT_CROSS_SYNDICATE_REQUEST: (networkId) =>
    `${baseURL}//syndicate/networks/${networkId}`,
  EDIT_SYNDICATE_MEMBER_COMMITMENT: (syndicateId) =>
    `${baseURL}/syndicate/members/${syndicateId}/change-info`,
  GET_SYNDICATE_REQUESTS_FOR_NETWORK: (networkId) =>
    `${baseURL}/syndicate/networks/${networkId}`,
  GET_NETWORKS_IN_SYNDICATE: (syndicateId) =>
    `${baseURL}/syndicate/syndicate-networks/${syndicateId}`,
  GET_NETWORK_MEMBERS_AS_SYNDICATE_ADMIN: (syndicateId, networkId) =>
    `${baseURL}/syndicate/network-members/${syndicateId}?networkId=${networkId}`,
  TOGGLE_SYNDICATE_STATUS: (action, syndicateId) =>
    `${baseURL}/syndicate/change-status/${action}/${syndicateId}`,
  BULK_SIGN_UP: () => `${baseURL}/network/bulk-signup`,
};
