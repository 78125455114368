import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import styles from 'dan-components/Contact/contact-jss';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

function SingleContact(props) {
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const {
    classes,
    socket,
    data,
    index,
    itemSelected,
    showDetail,
    keyword,
    title,
  } = props;

  const isLoaded = useRef(false);

  const { name, id } = data.group;

  const handleSelectGroup = () => {
    setNewMessagesCount(0);
    showDetail(index, id);
  };

  useEffect(() => {
    if (socket) {
      socket.on(
        `unread_messages_for_group_${id}`,
        async (unreadMessagesCount) => {
          setNewMessagesCount(unreadMessagesCount);
        }
      );
    }
  }, [socket, title]);

  if (name.toLowerCase().indexOf(keyword) === -1) {
    return null;
  }
  return (
    <ListItem
      button
      key={index}
      className={index === itemSelected ? classes.selected : ''}
      onClick={handleSelectGroup}
    >
      <ListItemAvatar>
        <Avatar
          alt={name}
          src='https://png.pngtree.com/png-vector/20190223/ourlarge/pngtree-vector-leader-of-group-icon-png-image_695853.jpg'
          className={classes.avatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {name.length > 20 ? `${name.substring(0, 20)}...` : name}{' '}
            {newMessagesCount ? <Chip label={newMessagesCount} /> : null}
          </>
        }
      />
    </ListItem>
  );
}

SingleContact.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  itemSelected: PropTypes.any.isRequired,
  keyword: PropTypes.string.isRequired,
  showDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  socket: state.toJS().profile.connection,
});

export default withStyles(styles)(connect(mapStateToProps)(SingleContact));
