/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppsIcon from '@material-ui/icons/Apps';

import dummy from 'dan-api/dummy/dummyContents';
import messageStyles from 'dan-styles/Messages.scss';
import link from 'dan-api/ui/link';
import { useDispatch, connect } from 'react-redux';
import * as io from 'socket.io-client';
import styles from './header-jss';
import { logout } from '../../redux/actions/authActions';
import { ENDPOINT } from '../../utils/constants';
import {
  setUnreadMessagesAction,
  setUnreadDealsAction,
  setUnreadEmailsAction,
  setConnection,
  setUnreadApplicationsAction,
} from '../../redux/actions/profileActions';
import cookies from '../../config/cookies';
import { Apps } from './AppBox';
import { getToken } from '../../redux/api';
import { useOutsideClick } from '../../utils/components/hooks';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';

const useStyles = makeStyles((theme) => ({
  badges: {
    fontSize: '30px',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
}));

function UserMenu({ classes, dark, profile }) {
  const customStyle = useStyles();
  const [, , removeCookie] = useCookies();
  const dispatch = useDispatch();
  const [unReadMessages, setUnreadMessages] = useState(0);
  const [unReadEmails, setUnreadEmails] = useState(0);
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    openMenu: null,
  });
  const [userId, setId] = useState(null);

  useEffect(() => {
    const socket = io(ENDPOINT, {
      path: '/socket/messages',
      transports: ['websocket', 'polling'],
      auth: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    socket.on('connect', () => {
      console.log('Websocket connection successful');
      dispatch(setConnection(socket));
    });
    socket.on('unread_messages', (count) => {
      setUnreadMessages(count);
      dispatch(setUnreadMessagesAction(count));
    });

    socket.on('unread_emails', (count) => {
      dispatch(setUnreadEmailsAction(count));
      setUnreadEmails(count);
    });

    socket.on('unseen_deals', (unseen_deals) => {
      dispatch(setUnreadDealsAction(unseen_deals));
    });

    socket.on('pending_applications', (count) => {
      dispatch(setUnreadApplicationsAction(count));
    });
  }, [userId]);

  const handleMenu = (menu) => (event) => {
    const { openMenu } = menuState;
    setMenuState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, openMenu: null });
  };

  const logoutUser = () => {
    dispatch(logout());
    removeCookie(LOGGED_IN_STATE_KEY, {
      ...(process.env.NODE_ENV === 'production' && {
        domain: '.conectivest.com',
      }),
      secure: true,
      path: '/',
    });
    setMenuState({ anchorEl: null, openMenu: null });
  };

  const { anchorEl, openMenu } = menuState;

  const appsContainerRef = useRef();
  useOutsideClick(appsContainerRef, handleClose);

  let { user, data, profile: profileData } = profile;
  if (!user) user = data || {};
  const { firstName } = user;

  const extractNetwork = (profile) => {
    let { user, data } = profile;
    if (!user) user = data || {};
    const { networkMembership = [] } = user;
    return networkMembership[0] || {};
  };

  let network = extractNetwork(profile);

  useEffect(() => {
    if (!userId) setId(profile && profile.data && profile.data.id);
  }, [profile]);

  const { isSuperAdmin } = network;

  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        aria-haspopup='true'
        onClick={handleMenu('apps')}
        color='inherit'
        aria-describedby='apps-menu'
        title='Conectivest Apps'
        aria-label='Conectivest Apps'
        className={classNames(
          classes.notifIcon,
          dark ? classes.dark : classes.light
        )}
      >
        <AppsIcon />
      </IconButton>
      {openMenu === 'apps' ? (
        <div className={classes.appsArea} ref={appsContainerRef}>
          <Apps />
        </div>
      ) : null}
      <Link to='/app/pages/chat' className={customStyle.link}>
        <IconButton color='inherit'>
          <Badge
            className={`${classes.badge} ${customStyle.badges}`}
            badgeContent={unReadMessages}
            color='secondary'
          >
            <i className='icon ion-ios-chatbubble-outline' />
          </Badge>
        </IconButton>
      </Link>
      {isSuperAdmin && (
        <Link to='/app/pages/email' className={customStyle.link}>
          <IconButton color='inherit'>
            <Badge
              className={`${classes.badge} ${customStyle.badges}`}
              badgeContent={unReadEmails}
              color='secondary'
            >
              <i className='class= ion-ios-email-outline' />
            </Badge>
          </IconButton>
        </Link>
      )}
      <IconButton
        aria-haspopup='true'
        onClick={handleMenu('notification')}
        color='inherit'
        className={classNames(
          classes.notifIcon,
          dark ? classes.dark : classes.light
        )}
      >
        <Badge className={classes.badge} color='secondary'>
          <i className='ion-ios-bell-outline' />
        </Badge>
      </IconButton>
      <Menu
        id='menu-notification'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.notifMenu}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        open={openMenu === 'notification'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <div className={messageStyles.messageInfo}>
            {/* <ListItemAvatar>
              <Avatar alt='User Name' src={avatarApi[0]} />
            </ListItemAvatar> */}
            <ListItemText
              primary={"You don't have any notification"}
              // secondary={dummy.text.date}
            />
          </div>
        </MenuItem>
        <Divider variant='inset' />
      </Menu>
      <Button onClick={handleMenu('user-setting')}>
        <Avatar
          alt={profileData ? firstName : dummy.user.avatar}
          src={profileData ? profileData.avatar : dummy.user.avatar}
        />
      </Button>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu === 'user-setting'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to={link.profile}>
          My Profile
        </MenuItem>
        {/* <MenuItem onClick={handleClose} component={Link} to={link.calendar}>
          My Calendar
        </MenuItem> */}
        {isSuperAdmin && (
          <MenuItem onClick={handleClose} component={Link} to={link.email}>
            My Inbox
            <ListItemIcon>
              <Badge
                className={classNames(classes.badge, classes.badgeMenu)}
                // badgeContent={2}
                color='secondary'
              />
            </ListItemIcon>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={() => logoutUser()}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false,
};

const mapStateToProps = (state) => ({
  force: state, // force state from reducer
  profile: state.toJS().profile.data,
  groupData: state.toJS().network.groups.data,
});

const UserProfileMapped = connect(mapStateToProps)(UserMenu);

export default withStyles(styles)(UserProfileMapped);
