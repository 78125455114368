/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './header-jss';
import { FOUNDER_ERP_URL, TIMELINE_URL } from '../../utils/constants';

const buttonStyles = {
  borderRadius: 7,
  padding: 6,
  margin: 6,
};

export function AppBox() {
  return (
    <>
      <a style={buttonStyles} href={FOUNDER_ERP_URL}>
        Founder
      </a>
      <a style={buttonStyles} href={TIMELINE_URL}>
        Timeline
      </a>
    </>
  );
}

export const Apps = withStyles(styles)(AppBox);
