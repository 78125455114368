module.exports = [
  {
    key: 'dashboard',
    // eslint-disable-next-line linebreak-style
    name: 'Dashboard',
    icon: 'ios-home-outline',
    linkParent: '/app',
  },
  {
    key: 'members',
    name: 'Members',
    icon: 'ios-people',
    linkParent: '/app/members',
  },
  {
    key: 'syndication',
    name: 'Syndication',
    icon: 'ios-people',
    linkParent: '/app/syndication',
    isBeta: true,
  },
  {
    key: 'app',
    name: 'Apps',
    icon: 'ios-appstore-outline',
    child: [
      {
        key: 'chat',
        name: 'Chat',
        link: '/app/pages/chat',
        icon: 'ios-mail-outline',
      },
    ],
  },
  {
    key: 'deal_flow',
    name: 'Deal flow',
    icon: 'ios-pie-outline',

    child: [
      {
        key: 'discover',
        name: 'Discover',
        link: '/app/pages/dealflow/discover',
        icon: 'ios-mail-outline',
      },
      {
        key: 'screening',
        name: 'Screening',
        link: '/app/pages/dealflow/screening',
        icon: 'ios-mail-outline',
      },
    ],
  },

  {
    key: 'transactions',
    name: 'Transactions',
    icon: 'ios-stats-outline',
    linkParent: '/app/transactions',
  },
];
