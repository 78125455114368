import cookies from '../config/cookies';
import { LOGGED_IN_STATE_KEY, OLD_LOGIN_KEY } from '../redux/constants';

export function setWithExpiry(key, value, ttl) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export const extractNetwork = (profile) => {
  let { user, data } = profile;
  if (!user) user = data || {};
  const { networkMembership = [] } = user;
  return networkMembership[0] || {};
};

export function truncateString(str, num = 10) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function isLoggedIn() {
  const cookie =
    cookies.get(LOGGED_IN_STATE_KEY) ||
    cookies.get(OLD_LOGIN_KEY) ||
    localStorage.getItem(OLD_LOGIN_KEY) ||
    sessionStorage.getItem(OLD_LOGIN_KEY);
  return !!cookie;
}

export function clearCookies() {
  cookies.remove(OLD_LOGIN_KEY);
  localStorage.removeItem(OLD_LOGIN_KEY);
  sessionStorage.removeItem(OLD_LOGIN_KEY);
  cookies.remove(LOGGED_IN_STATE_KEY, {
    ...(process.env.NODE_ENV === 'production' && {
      domain: '.conectivest.com',
    }),
    secure: true,
  });
}
