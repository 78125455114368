import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { Dialog, DialogTitle } from '@material-ui/core';
import ComposeEmailForm from './ComposeEmailForm';
import FloatingPanel from '../Panel/FloatingPanel';
import styles from './email-jss';
import { CloseDialogButton } from '../../utils/components/CloseModalButton';

function ComposeEmail(props) {
  const {
    classes,
    open,
    closeForm,
    sendEmail,
    to,
    subject,
    validMail,
    inputChange,
    subjectError,
    compose,
    snack: Snack,
  } = props;
  return (
    <div>
      {compose ? (
        <Tooltip title='Compose Email'>
          <Fab
            color='secondary'
            onClick={() => compose()}
            className={classes.addBtn}
          >
            <Add />
          </Fab>
        </Tooltip>
      ) : null}
      <Dialog
        scroll='paper'
        open={open}
        onClose={closeForm}
        title='Compose Email'
        disableBackdropClick
        // extraSize
      >
        <DialogTitle>Compose Email</DialogTitle>
        <CloseDialogButton onClick={closeForm} />
        <ComposeEmailForm
          to={to}
          subject={subject}
          validMail={validMail}
          sendEmail={sendEmail}
          closeForm={closeForm}
          inputChange={inputChange}
          subjectError={subjectError}
        />
        {Snack || null}
      </Dialog>
    </div>
  );
}

ComposeEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  validMail: PropTypes.string.isRequired,
  compose: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ComposeEmail);
