import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Outer from '../Templates/Outer';
import {
  Login,
  Register,
  ResetPassword,
  ComingSoon,
  Maintenance,
  NotFound,
} from '../pageListAsync';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';
import { PENDING_PATH_KEY } from '../../utils/constants';

function Auth() {
  return (
    <Outer>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/reset-password' component={ResetPassword} />
        <Redirect from='/*' to='/login' />
        {/* <Route path='/maintenance' component={Maintenance} />
        <Route path='/coming-soon' component={ComingSoon} /> */}
        <Route component={NotFound} />
      </Switch>
    </Outer>
  );
}

export default Auth;
