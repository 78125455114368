import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function NotificationPermissionModal({
  open,
  close,
  askForPermission,
}) {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby='notification-permission-dialog'
      aria-describedby='we need your permission to give you real time notifications about events on your network'
    >
      <DialogTitle id='notification-permission-dialog'>
        Notification permission, please?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          We need notification permission to be able to bring you real
          time information and updates about deals and conversations going on in your
          network.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            askForPermission();
            close();
          }}
          color='primary'
        >
          Alright, do it.
        </Button>
        <Button onClick={close} color='secondary'>
          No, thanks.
        </Button>
      </DialogActions>
    </Dialog>
  );
}
