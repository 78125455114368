import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route } from 'react-router-dom';
import { LOGGED_IN_STATE_KEY } from '../redux/constants';
import { PENDING_PATH_KEY } from './constants';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export default ProtectedRoute;
