import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_FORM_DATA_REQUEST,
  CREATE_PROFILE_REQUEST,
  CLEAR_PROFILE_MESSAGES,
  UPDATE_PROFILE_REQUEST,
  SOCKET_CONNECTION,
  UNREAD_MESAGES,
  UNREAD_EMAILS,
  UNREAD_DEALS,
  UNREAD_APPLICATIONS
} from '../types';

export const fetchProfile = (onSuccess) => ({
  type: FETCH_PROFILE_REQUEST,
  onSuccess,
});

export const fetchProfileFormData = () => ({
  type: FETCH_PROFILE_FORM_DATA_REQUEST,
});

export const clearMessages = () => ({ type: CLEAR_PROFILE_MESSAGES });

export const createProfile = (payload, onSuccess) => ({
  type: CREATE_PROFILE_REQUEST,
  payload,
  onSuccess,
});

export const updateProfile = (payload, onSuccess) => ({
  type: UPDATE_PROFILE_REQUEST,
  payload,
  onSuccess,
});

export const setConnection = (payload) => ({
  type: SOCKET_CONNECTION,
  payload,
});

export const setUnreadMessagesAction = (payload) => ({
  type: UNREAD_MESAGES,
  payload,
});

export const setUnreadDealsAction = (payload) => ({
  type: UNREAD_DEALS,
  payload,
});

export const setUnreadEmailsAction = (payload) => ({
  type: UNREAD_EMAILS,
  payload,
});

export const setUnreadApplicationsAction = (payload) => ({
  type: UNREAD_APPLICATIONS,
  payload,
});

// export const unreadMesage
