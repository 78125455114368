import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from 'containers/Pages/Standalone/NotFoundDedicated';
import { useCookies } from 'react-cookie';
import Auth from './Auth';
import Application from './Application';
import LandingCorporate from './Landing';
// import ArticleNews from './ArticleNews';
import ThemeWrapper from './ThemeWrapper';

import ProtectedRoute from '../../utils/ProtectedRoute';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';
import { PENDING_PATH_KEY } from '../../utils/constants';
// import globalStyles from '../../styles/global.css';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function App() {
  const [redirectApp] = useState(
    sessionStorage.getItem(PENDING_PATH_KEY) || '/app'
  );
  const [cookies] = useCookies();

  useEffect(
    () => () => {
      sessionStorage.removeItem(PENDING_PATH_KEY);
    },
    []
  );

  useEffect(() => {
    console.log('cookies', cookies);
  }, [cookies]);

  if (!cookies[LOGGED_IN_STATE_KEY]) {
    return (
      <ThemeWrapper>
        <Switch>
          <Route component={Auth} />
          <Route component={NotFound} />
        </Switch>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper>
      <Switch>
        <ProtectedRoute path='/app' component={Application} />
        <Redirect exact from='/login' to={redirectApp} />
        <Redirect exact from='/' to='/app' />
        <Route component={NotFound} />
      </Switch>
    </ThemeWrapper>
  );
}

export default App;
