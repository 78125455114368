import React, { useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Send from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Type from 'dan-styles/Typography.scss';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import MessageField from './MessageField';
import ChatHeader from './ChatHeader';
import { CircularIndeterminateSm } from '../Progress/CircularIndeterminate';
import styles from './chatStyle-jss';
dayjs.extend(localizedFormat);

function ChatRoom(props) {
  const field = useRef(null);
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);

  const handleWrite = (e, value) => {
    setMessage(value);
  };

  const resetInput = () => {
    const ctn = document.getElementById('roomContainer');
    setMessage('');
    field.current.value = '';
    setTimeout(() => {
      ctn.scrollTo(0, ctn.scrollHeight);
    }, 300);
  };

  const sendMessageByEnter = (event, msg) => {
    const { sendMessage } = props;
    if (event.key === 'Enter' && event.target.value !== '') {
      sendMessage(msg.__html);
      resetInput();
    }
  };

  const sendMessage = (msg) => {
    props.sendMessage(msg.__html);
    resetInput();
  };

  useEffect(() => {
    if (messagesEndRef) {
      messagesEndRef.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  const html = { __html: message };
  const {
    classes,
    dataChat,
    chatSelected,
    dataContact,
    showMobileDetail,
    groupMembers,
    remove,
    hideDetail,
    userId,
    loading,
  } = props;
  const getChat = (dataArray) =>
    dataArray.map((data, i) => {
      const {
        messageSentBy: { groupMemberDetails },
      } = data;
      const renderHTML = {
        __html: DOMPurify.sanitize(data.message),
      };
      return (
        <li
          className={
            data.messageSentBy.userId !== userId ? classes.from : classes.to
          }
          key={i}
        >
          <time dateTime={data.createdAt}>
            {dayjs(data.createdAt).format('MMM, D YYYY LT')}
          </time>
          <div className={classes.talk}>
            <p>
              <span>
                {data.messageSentBy.userId !== userId && (
                  <b
                    style={{
                      fontSize: 13,
                      margin: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    {[
                      groupMemberDetails.firstName,
                      groupMemberDetails.lastName,
                    ].join(' ')}
                  </b>
                )}
                <div dangerouslySetInnerHTML={renderHTML} />
              </span>
            </p>
          </div>
        </li>
      );
    });
  return (
    <div
      className={classNames(
        classes.root,
        classes.content,
        showMobileDetail ? classes.detailPopup : ''
      )}
    >
      <ChatHeader
        dataContact={dataContact}
        chatSelected={chatSelected}
        groupMembers={groupMembers}
        remove={remove}
        showMobileDetail={showMobileDetail}
        hideDetail={hideDetail}
        loading={loading}
      />
      <div style={{ flex: 1, overflow: 'auto' }} ref={messagesEndRef}>
        <ul className={classes.chatList} id='roomContainer'>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 150,
                minHeight: 150,
              }}
            >
              <CircularIndeterminateSm size='46px' color='#0566AD' />
            </div>
          ) : dataChat.length > 0 ? (
            getChat(dataChat)
          ) : (
            <Typography
              display='block'
              variant='caption'
              className={Type.textCenter}
            >
              {"You haven't made any conversation yet"}
            </Typography>
          )}
        </ul>
      </div>
      <div>
        <Paper className={classes.writeMessage}>
          <MessageField
            onChange={handleWrite}
            passedRef={field}
            placeholder='Type a message'
            fieldType='input'
            value={message}
            onKeyPress={(event) => sendMessageByEnter(event, html)}
          />
          <Tooltip id='tooltip-send' title='Send'>
            <div>
              <IconButton
                mini='true'
                color='secondary'
                disabled={message === ''}
                onClick={() => sendMessage(html)}
                aria-label='send'
                className={classes.sendBtn}
              >
                <Send />
              </IconButton>
            </div>
          </Tooltip>
        </Paper>
      </div>
    </div>
  );
}

export default withStyles(styles)(ChatRoom);
